<template>
  <div class="wrapper">
    <ul class="options-toolbar">
      <li><button class="btn btn-toggle" :class="{ active_custom: this.dark_theme }" @click="getTheme" v-tooltip.auto="'Переключить тему'">
        <span class="handle"></span>
      </button></li>
      <li><router-link class="" :to="{ name: 'forum' }" v-tooltip.auto="'Список топиков'">
        <i class="bi bi-view-list"></i> Форум
      </router-link></li>
      <li><router-link class="" :to="{ name: 'blogs' }" v-tooltip.auto="'Список записей'">
        <i class="bi bi-textarea-t"></i> Блог
      </router-link></li>
      <li><router-link class="" :to="{ name: 'faq' }" v-tooltip.auto="'Помощь по интерфейсу сайта'">
        <i class="bi bi-patch-question"></i> Помощь
      </router-link></li>
    </ul>
    <div class="container">
      <p style="color:black;">Между Сциллой и Харибдой<br>&copy; 2023</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptionsToolbar',
  props: {
    // определение свойства для использования в компоненте
    dark_theme: Boolean,
  },
  methods: {
    getTheme: function() {
      this.$emit('update:dark_theme', !this.dark_theme) // генерируем событие
      localStorage.setItem('dark_theme', !this.dark_theme)
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-toggle::before {
  content: '\F46B\00a0\00a0\00a0Светлая'; /* иконка лампочки */
  font-family: 'bootstrap-icons', Avenir, Helvetica, Arial, sans-serif;
}

.btn-toggle.active_custom::before {
  content: '\F46A\00a0\00a0\00a0Тёмная'; /* иконка выключенной лампочки */
  font-family: 'bootstrap-icons', Avenir, Helvetica, Arial, sans-serif;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 85vh;
}
.container {
  margin: auto 0 0;
}

ul.options-toolbar {
  list-style: none;
  text-align: left;
} ul.options-toolbar li {
  padding: 0.4em;
} ul.options-toolbar li i {
  padding-right: 0.5em;
}
ul.options-toolbar a {
  color: var(--text-color);
  text-decoration: none;
  padding-left: 0.6em;
} ul.options-toolbar a:hover {
  color: #fff;
}
</style>
