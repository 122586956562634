<template>
  <router-view v-touch:swipe="onSwipe" />
  <div class="offcanvas offcanvas-start" :class="{ 'show': isMenuOpen }" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" v-touch:swipe="menuToggle">
    <div class="offcanvas-header">
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" @click="menuToggle"></button>
    </div>
    <div class="offcanvas-body">
      <OptionsToolbar :dark_theme="dark_theme" @update:dark_theme="updateDarkTheme($event)" />
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'

import OptionsToolbar from './components/OptionsToolbar.vue'

export default {
  name: 'App',
  components: {
    OptionsToolbar,
  },
  data() {
    return {
      dark_theme: true,
      isMenuOpen: false,
    }
  },
  created() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    const prefersLight = window.matchMedia('(prefers-color-scheme: light)').matches
    const hasNoPreference = !prefersDark && !prefersLight

    if (prefersDark) {
      this.dark_theme = true
    }
    else if (prefersLight) {
      this.dark_theme = false
    }
    else if (hasNoPreference) {
      const hour = new Date().getHours()
      const isDayTime = hour > 6 && hour < 20
      this.dark_theme = !isDayTime
    }

    if (localStorage.getItem('dark_theme')) {
      this.dark_theme = this.toBoolean(localStorage.getItem('dark_theme'))
    }

    this.setTheme()
  },
  methods: {
    // Адекватное приведение переменной к boolean типу
    toBoolean(str) {
      if (typeof str === 'undefined' || str === null) {
        return false;
      } else if (typeof str === 'string') {
          switch (str.toLowerCase()) {
            case 'false':
            case 'no':
            case '0':
            case "":
              return false;
            default:
              return true;
          }
      } else if (typeof str === 'number') {
        return str !== 0
      }
      else {return true}
    },
    // Убирает все HTML теги, меняет <br> на \n и добавляет … для предпросмотра больших постов
    stripHTML(text) {
      const maxLength = 256
      let plainText = text.replace(/<br>/gi, '\n')
                          .replace(/(<([^>]+)>)/gi, '')
                          .replace(/https:\/\/soundcloud.com\/\S*/g, '&#127925; Аудиозапись из SoundCloud<br>')
                          .replace(/https:\/\/youtube.com\/\S*/g, '&#127925; Аудио/Видео из YouTube<br>')
                          .replace(/https:\/\/youtu.be\/\S*/g, '&#127925; Аудио/Видео из YouTube<br>')
      if (plainText.length > maxLength) {
        plainText = plainText.substring(0, maxLength) + "…"
      }
      return plainText
    },
    // Отображает/скрывает меню
    menuToggle() {
      this.isMenuOpen = !this.isMenuOpen
    },
    // Слушает свайпы
    onSwipe(direction) {
      if (direction == 'left') {
        this.$router.push('/faq')
        console.log("Swiped Left ")
      }
      else if (direction == 'right') {
        this.isMenuOpen = !this.isMenuOpen
        console.log("Swiped Right ")
      }
      console.log(direction)
    },
    // Устанавливает цвета для выбранной темы
    setTheme() {
      const root = document.documentElement
      if (this.dark_theme === false) {
        root.style.setProperty('--bg-color', '#fdf6e3')
        root.style.setProperty('--bg-color-item', '#eee8d5')
        root.style.setProperty('--text-color', '#839496')
        root.style.setProperty('--text-meta-color', '#839496')
        root.style.setProperty('--color-border-item', '#fdf6e3')
      } else {
        root.style.setProperty('--bg-color', '#002b36')
        root.style.setProperty('--bg-color-item', '#073642')
        root.style.setProperty('--text-color', '#657b83')
        root.style.setProperty('--text-meta-color', '#657b83')
        root.style.setProperty('--color-border-item', '#002b36')
      }
    },
    // Обновляет текущую тему
    updateDarkTheme: function(value) {
      this.dark_theme = value // обновляем состояние
      this.setTheme()
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
.btn-cancel {
  border: 1px solid #2c3e50;
  box-shadow: none; /* добавляем тень при наведении */
  transition: box-shadow 0.1s ease-in-out; /* добавляем плавный переход */
}
.btn-cancel:hover {
  background-color: #dc322f !important;
  background-image: var(--bs-gradient);
  color: #eee8d5 !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.btn-cancel:active {
  border: none;
  box-shadow: none;
}
.btn-go {
  border: 1px solid #859900;
  background-color: #859900 !important;
  color: #eee8d5 !important;
}
.btn-go:hover {
  background-color: #5f8700 !important;
}
.btn-go:active {
  border: none;
  box-shadow: none;
}
.bg-darkred {
  background-color: #390000 !important;
}
.bg-darkgreen {
  background-color: #003300 !important;
}
body, li.list-group-item, textarea {
  background-color: var(--bg-color);
  color: var(--text-color);
}
textarea {
  border: 1px solid var(--text-color);
  border-radius: 0.2em;
  padding-left: 10px;
  width: 100%;
}
div.list-group-item, a.list-group-item, button, button.btn, a.btn {
  background-color: var(--bg-color-item);
  color: var(--text-color);
}
button.btn.active {
  background-color: var(--bg-color-item);
  color: var(--text-color);
  border: none;
}
span.badge, p, p.lead, h1 {
  color: var(--text-color);
}
body i {
  font-size: 1.2rem;
}
.list-group-item {
  /* border: 1px solid var(--color-border-item); */
  border: none;
}
.text-meta {
  color: var(--text-meta-color);
}
.offcanvas {
  background-color: var(--bg-color-item);
  color: var(--text-color);
  box-shadow: inset 0px 0px 10px rgba(0,0,0,.5);
}
</style>
