import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import FloatingVue from 'floating-vue'
import Vue3TouchEvents from 'vue3-touch-events'

import App from './App.vue'
import ThreadList from './views/ThreadList.vue'
import ThreadView from './views/ThreadView.vue'
import BlogList from './views/BlogList.vue'
import FaqPage from './views/FaqPage.vue'

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'floating-vue/dist/style.css'

const router = createRouter({
  routes: [
    {
      path: '/',
      component: ThreadList,
      name: 'forum'
    },
    { path: '/thread/:id',
      component: ThreadView,
      name: 'thread' 
    },
    {
      path: '/blogs',
      component: BlogList,
      name: 'blogs'
    },
    { path: '/faq',
      component: FaqPage,
      name: 'faq' 
    }
  ],
  history: createWebHistory()
})

window.yt_host = `https://vid.priv.au`        // Хост по-умолчанию для мультимедиа из YouTube
window.base_url = `https://manjaro.poohprod.ru`         // Хост по-умолчанию
window.api_host = `https://api.poohprod.ru/scylla/` // Хост по-умолчанию для API

const app = createApp(App)
app.use(router)
app.use(FloatingVue)
app.use(Vue3TouchEvents)
app.mount('#app')

app.mixin({
  methods: {
    // Адекватное приведение переменной к boolean типу
    toBoolean(str) {
      if (typeof str === 'undefined' || str === null) {
        return false;
      } else if (typeof str === 'string') {
          switch (str.toLowerCase()) {
            case 'false':
            case 'no':
            case '0':
            case "":
              return false;
            default:
              return true;
          }
      } else if (typeof str === 'number') {
        return str !== 0
      }
      else {return true}
    },
    // Убирает все HTML теги, меняет <br> на \n и добавляет … для предпросмотра больших постов
    stripHTML(text) {
      const maxLength = 256
      let plainText = text.replace(/<br>/gi, '\n')
                          .replace(/(<([^>]+)>)/gi, '')
                          .replace(/https:\/\/soundcloud.com\/\S*/g, '&#127925; Аудиозапись из SoundCloud<br>')
                          .replace(/https:\/\/youtube.com\/\S*/g, '&#127925; Аудио/Видео из YouTube<br>')
                          .replace(/https:\/\/youtu.be\/\S*/g, '&#127925; Аудио/Видео из YouTube<br>')
      if (plainText.length > maxLength) {
        plainText = plainText.substring(0, maxLength) + "…"
      }
      return plainText
    },
    // Добавляет виджет проигрывателя для мультимедиа из SoundCloud и YouTube
    getWidgets() {
      this.widgets.forEach(function(pattern) {
        if (Array.isArray(this.posts)) {
          for (let i = 0; i < this.posts.length; i++) {
            const post = this.posts[i].post;
            if (typeof post === 'string') {
              this.posts[i].post = post.replace(pattern.pattern, function(url) {
                const embedUrl = pattern.embedUrl(url)
                return `<iframe width='100%' height='166' scrolling='no' frameborder='no' src='${embedUrl}'></iframe>`
              });
            }
          }
        }
      }, this)
    },
    
  },

})
