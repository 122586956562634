<template>
  <main style="min-height:100%;">
    <div class="container-sm-fluid container-md">

      <aside class="sticky-top d-none d-lg-block my-5 me-5 float-start" style="height:100%;width:25%;">
        <div class="list-group shadow">
          <button class="list-group-item list-group-item-action px-2 btn btn-sm bg-gradient">
            <i class="bi bi-vector-pen"></i>
            Написать
          </button>
        </div>
      </aside>

      <div class="row" style="max-width:100%;">
        <GetPosts />
      </div>

    </div>
  </main>
</template>

<script>
import GetPosts from '@/components/GetPosts.vue'

export default {
  components: {
    GetPosts
  },
  mounted() {
    // Костыль для скролла к нужному посту по ссылке
    const PostID = window.location.hash.substring(1)
    if (PostID) {
      const Post = document.getElementById(PostID)
      if (Post) {
        Post.scrollIntoView({ behavior: "smooth" })
      } else {
        setTimeout(() => {
          const Post = document.getElementById(PostID)
          if (Post) {
            Post.scrollIntoView({ behavior: "smooth" })
            Post.style.border = "2px solid darkred"
          } else {
            setTimeout(() => {
              const Post = document.getElementById(PostID)
              if (Post) {
                Post.scrollIntoView({ behavior: "smooth" })
                Post.style.border = "2px solid darkred"
              } else {
                console.log('Не могу показать этот пост :(')
              }
            }, 2000)
          }
        }, 900)
      }
    }
  }

}
</script>