<template>
  <main style="min-height:100%;">
    <div class="container-sm-fluid container-md">
      <aside class="sticky-top d-none d-lg-block my-5 me-5 float-start" style="height:100%;width:25%;">
          <div>
            <a href="#">
                Введение
            </a>
          </div>
      </aside>
      <div class="row" style="max-width:100%;">
        <div class="text-start py-3">
            <h1 class="text-center pb-5">Добро пожаловать!</h1>
            <p class="lead">Надеемся тебе здесь будет комфортно.</p>
            <p>Как и на любом форуме, здесь есть тематические топики. Общаться в них можно соблюдая установленные 
              правила (если таковые есть).</p>
            <p>Свайп влево, всегда вернёт тебя в этот раздел. А свайп вправо, откроет навигационное меню.</p>
            <p>Помимо привычных топиков и постов есть раздел <strong>Блоги</strong>, и он полностью автономный. 
              В этот раздел попадают посты из топиков соответствующие установленным критериям, перечень критериев 
              определён администрацией ресурса и может меняться.</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  
}
</script>