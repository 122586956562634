<template>
<div v-if="live" class="list-group px-0 py-3" style="max-width:100%;font-size:0.7em;">
  <router-link v-for="post in live" :key="post.id" :to="'thread/' + post.topic_id + '#' + post.id" style="text-decoration:none;overflow-x:hidden;">
    <strong class="text-success">{{ post.username }}</strong> <small class="text-meta">{{ post.date }}</small><br><span v-html="stripHTML(post.post)" style="color: #657b83"></span>
  </router-link>
  <a class="my-2 text-success" href="#">Весь эфир</a>
</div>
<div v-else v-tooltip="{ content: 'Здесь будет отображаться так называемый <b>прямой эфир</b>,<br>посты из всех тем в хронологическом порядке.', html: true, }"><p class="lead">Тишина в эфире.</p></div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      live: null
    }
  },
  mounted() {
    axios
      .get(window.api_host + '/get-live.php')
      .then(response => (this.live = response.data))
  },
  
}
</script>