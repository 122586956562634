<template>
  <main style="min-height:100%;">
    <div class="container-sm-fluid container-md">

      <aside class="sticky-top d-none d-lg-block my-5 me-5 float-start" style="height:100%;width:25%;">
        <div class="list-group shadow">
          <button class="list-group-item list-group-item-action px-2 btn btn-sm btn-go bg-gradient">
            <i class="bi bi-bookmark-plus"></i>
            Создать
          </button>
        </div>
        <div class="text-start pt-3">
          <GetLive />
        </div>
      </aside>

      <div class="row" style="max-width:100%;">
        <p class="text-meta pt-3">Свайпни влево,<br>чтобы открыть помощь</p>
        <GetThreads />
      </div>

    </div>
  </main>
</template>

<script>
import GetThreads from '../components/GetThreads.vue'
import GetLive from '../components/GetLive.vue'

export default {
  components: {
    GetThreads,
    GetLive
  }

}
</script>
