<template>
<div v-if="threads" class="list-group px-0 py-3" style="max-width:100%;">
  <div v-for="category in categories" :key="category.id">
    <h2 class="folder-label">{{ category.name }}</h2>
    <div class="clearfix"></div>
    <router-link v-for="thread in sortedThreadsForCategory(category.id)" :key="thread.thread_id" :to="{ name: 'thread', params: { id: thread.thread_id }}" class="list-group-item d-flex gap-2 shadow">
      <img v-if="thread.cover" :src="'http://scylla.loc/uploads/covers/thumbs/' + thread.cover + '.webp'" class="flex-shrink-0 rounded" width="94" height="94">
      <img v-else src="https://via.placeholder.com/150" class="flex-shrink-0 rounded" width="94" height="94">
      <div class="d-flex flex-column text-start ps-2" style="overflow:hidden;">
        <h5 class="d-flex mb-md-3 fw-bold" style="color: #657b83;letter-spacing:-1px;">{{ thread.thread_name }}</h5>
        <p v-html="stripHTML(thread.post)" class="small" style="line-height: 0.98;color: #657b83;overflow:hidden;"></p>
      </div>
      <div class="position-absolute text-end pe-2 me-3 end-0 w-50 d-none d-md-inline" style="color:#657b83;letter-spacing:-1px;"><span class="badge">{{ thread.date }} от {{ thread.username }}</span></div>
    </router-link>
  </div>
</div>
<div v-else><p class="lead">Пока не создано ни одной темы.</p></div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      threads: null
    }
  },
  mounted() {
    axios
      .get(window.api_host + '/get-threads.php')
      .then(response => (this.threads = response.data))
  },
  computed: {
    categories() {
      // Получаем уникальные категории
      return [...new Map(this.threads.map((thread) => [thread.category_id, { id: thread.category_id, name: thread.category_name }])).values()];
    }
  },
  methods: {
    sortedThreadsForCategory(category_id) {
      // Фильтруем по категориям и сортируем по id
      return this.threads
        .filter(thread => thread.category_id === category_id)
        .sort((a, b) => a.id - b.id)
    }
  }
}
</script>

<style scoped>
.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1) !important;
}
.folder-label {
	border-bottom: 40px solid var(--text-color);
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
  border-radius: 1.85em 1.85em 0 0;
	height: 0;
	width: 20vh;
  font-size: 1.2em;
  font-weight: bold;
  float: right;
  margin: 18px 10px -5px 0;
}
@media (max-width: 1000px) {
  .folder-label {
    width: 25vh;
  }
}
@media (max-width: 767px) {
  .folder-label {
    width: 60%;
  }
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
</style>