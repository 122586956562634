<template>
<div v-if="posts" class="list-group px-0 py-3" style="max-width:100%;">
  <div v-for="post in posts" :key="post.id" @update="onUpdatePost" class="list-group-item shadow text-start py-5" style="overflow-x:hidden;">
    <p><span class="fw-bold pe-1">{{ post.username }} в <router-link :to="'thread/' + post.topic_id" class="text-meta">{{ post.topic_name }}</router-link></span><span class="small float-end text-meta">{{ post.date }}</span></p>
    <div v-if="!post.editing">
      <p v-html="post.post"></p>
      <button class="btn btn-sm" type="button" @click="post.editing = true">Редактировать</button>
    </div>
    <div v-else>
      <div><textarea @input="resizeTextarea" contenteditable="true" v-text="post.post" @blur="savePost"></textarea></div>
      <button class="btn btn-sm btn-go bg-gradient me-1 px-4" type="button" @click="post.editing = false">Сохранить</button>
      <button class="btn btn-sm btn-cancel px-4" type="button" @click="post.editing = false">Отменить</button>
    </div>
  </div>
</div>
<div v-else><p class="lead">Здесь пока нет постов.</p></div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    post: Object
  },
  methods: {
    savePost() {
      // сохраняем изменения
      this.$emit('update', this.post)
      console.log('Новый текст поста сохранён!')
    },
    // Костыль обновляет размер (высота) поля ввода textarea от вводимого контента
    resizeTextarea(event) {
      event.target.style.height = "auto"
      event.target.style.height = event.target.scrollHeight + "px"
      console.log('Обновление размера поля ввода')
    }
  },
  data() {
    const players_widget = [
      { pattern: /https:\/\/soundcloud.com\/\S*/gi, embedUrl: function(url) { return `https://w.soundcloud.com/player/?url=${url}&show_artwork=true`.replace(/<[^>]+>/g, '') } },
      { pattern: /https:\/\/youtu.be\/\S*/gi, embedUrl: function(url) { return window.yt_host + `/embed/${url.split('/')[3]}`.replace(/<[^>]+>/g, '') } },
      { pattern: /https:\/\/music.youtube.com\/\S*/gi, embedUrl: function(url) { return window.yt_host + `/embed/${url.split('=')[3]}`.replace(/<[^>]+>/g, '') } },
      // добавьте другие паттерны, если необходимо
    ]
    return {
      posts:   null,
      widgets: players_widget
    }
  },
  mounted() {
    axios
      .get(window.api_host + '/get-posts-from-blog.php')
      .then(response => {
        this.posts = response.data
        this.getWidgets(this.posts)
      })
  },
  updated() {
    // Костыль для рендера textarea нужного размера по высоте, под контент
    const tx = document.getElementsByTagName("textarea")
    if (tx[0] && !tx[0].hasAttribute("data-textarea-initialized")) {
      tx[0].setAttribute("data-textarea-initialized", "true") // устанавливаем атрибут, чтобы не инициализировать повторно
      tx[0].scrollIntoView({ behavior: 'smooth' })
      tx[0].setAttribute("style", "height:" + (tx[0].scrollHeight) + "px;overflow-y:hidden;")
    }
    // Этот цикл изменит высоту всех полей textarea на странице
    /*
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;")
      tx[i].addEventListener("input", OnInput, false)
    }
    */
    console.log('Сработало событие обновления DOM')
  }

}
</script>
